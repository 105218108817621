<template>
  <div>
    <el-link type='primary' @click='goDeptDetail(info.id)' v-if="userPermissions.indexOf('dept_detail')>-1"
             target='_blank'>
      {{ info.name || '' }}
    </el-link>
    <span v-else>{{ info.name || '' }}</span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DeptNameTag',
  props: {
    info: {
      type: Object,
      default() {
        return { id: null, name: '' }
      }
    }
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  data() {
    return {
      is_blank: true
    }
  },
  methods: {
    goDeptDetail(id) {
      if (id) {
        if (this.is_blank) {
          const { href } = this.$router.resolve({
            name: 'team',
            query: { group_id: id }
          })
          window.open(href, '_blank')

        } else {
          this.$router.push({ name: 'team', query: { group_id: id } })
        }
      } else {
        this.$notify.error('部门ID未知，无法查看详情')
      }

    }
  }
}
</script>

<style scoped>

</style>
